import React from 'react'
import { graphql } from 'gatsby'
import QRCode from 'qrcode.react'
import { Box, Flex } from 'components/primitives'
import Heading from 'components/core/Heading'
import Text from 'components/core/Text'

const AttributeValue = props => (
  <Text as='span' fontWeight={400} color='text' fontSize={0} {...props} />
)

const QR = ({
  data: {
    allAirtable: { edges }
  },
  ...props
}) => {
  const componentRef = React.useRef()
  const products = edges.map(({ node: { data } }) => data)
  return (
    <div>
      {/* <ReactToPrint
        trigger={() => <button>Print this out!</button>}
        content={() => componentRef.current}
      /> */}
      <ItemList products={products} ref={componentRef} />
    </div>
  )
}

class ItemList extends React.Component {
  render() {
    return (
      <>
        {this.props.products.map(p => (
          <Item p={p} key={p.handle} />
        ))}
      </>
    )
  }
}

const Item = ({ p, ...props }) => (
  <Box mt={'18px'}>
    <AttributeValue>{p.name}</AttributeValue>
    <Flex mt={'12px'}>
      <Box mr='auto'>
        <Heading fontSize={1} fontWeight='heading'>
          {p.name}
        </Heading>
        <Box mt={-2}>
          <AttributeValue>{p.tile_type}, </AttributeValue>
          <AttributeValue>{p.derived_size}</AttributeValue>
        </Box>
      </Box>
      <Box>
        <QRCode
          id={p.handle}
          size={56}
          value={`https://tmtcollective.com/products/${p.handle}?ref=qr`}
        />
      </Box>
    </Flex>
  </Box>
)

export default QR

export const productsQuery = graphql`
  query {
    allAirtable(filter: { table: { eq: "제품" } }) {
      edges {
        node {
          data {
            name
            type
            tile_type
            colors
            tags
            surface
            width
            length
            derived_size
            coverage
            derived_coverage
            price_per_coverage
            price_per_coverage_without_tax
            price_per_box
            pieces_per_box
            has_samples
            series
            series_ko
            series_variant
            handle
            handle_ko
            description_series
            description
            video_url
            shop_url
            created_at
            updated_at
          }
        }
      }
    }
  }
`
